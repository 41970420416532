import React, { useContext, useState } from "react";

import { Grid, Link, Typography } from "@mui/material";

import { LanguageContext } from "../../languages/LanguageContext";
import {
  BBBlogo,
  GCLAlogo,
  GoogleLogo,
  NLALogo,
  YelpLogo,
} from "../../resources/images";
import CustomSponsorImg from "../CustomSponsorImg";
import CustomModal from "../CustomModal";
import TermsAndCondition from "../TermsAndCondition";
import PrivacyPolicy from '../PrivacyPolicy';

const ContactSponsors = () => {
  const { i18n } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const [showModalPrivacy, setShowModalPrivacy] = useState(false);

  const handleShowModalPrivacy = () => setShowModalPrivacy(true);
  const handleCloseModalPrivacy = () => setShowModalPrivacy(false);

  return (
    <>
      <Grid
        container
        alignSelf="center"
        marginTop={10}
        justifyContent={"space-between"}
        sx={{
          paddingTop: 2,
          paddingBottom: 5,
          paddingLeft: 3,
          paddingRight: 3,
          background: "white",
          height: {
            lg: "8rem",
            md: "8rem",
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <CustomSponsorImg src={GoogleLogo.src} />
        <CustomSponsorImg src={NLALogo.src} />
        <CustomSponsorImg src={BBBlogo.src} />
        <CustomSponsorImg src={GCLAlogo.src} />
        <CustomSponsorImg src={YelpLogo.src} />
      </Grid>
      <Grid
        container
        padding={1}
        justifyContent={"space-between"}
        marginBottom={10}
        sx={(t) => ({
          background: `${t.palette.primary.main}`,
          height: {
            lg: "8rem",
            md: "8rem",
            sm: "100%",
            xs: "100%",
          },
        })}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignSelf: {
              lg: "start",
              md: "start",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Typography
            id="address1"
            sx={{
              fontSize: {
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "6.3vw",
              },
            }}
            variant="h5"
          >
            {i18n.swanData.address1}
          </Typography>
          <Typography
            id="address2"
            sx={{
              fontSize: {
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "6.3vw",
              },
            }}
            variant="h5"
          >
            {i18n.swanData.address2}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            alignSelf: {
              lg: "start",
              md: "start",
              sm: "none",
              xs: "none",
            },
            textAlign: {
              lg: "right",
              md: "right",
              sm: "left",
              xs: "left",
            },
          }}
        >
          <Link
            variant="h5"
            display="block"
            id="contactPhone"
            sx={{
              color: "white",
              textDecoration: "underline",
              fontSize: {
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "6.3vw",
              },
            }}
            href={`tel:${i18n.swanData.phoneCall}`}
          >
            {i18n.swanData.phoneShow}
          </Link>
          <Link
            variant="h5"
            id="contactEmail"
            sx={{
              color: "white",
              textDecoration: "underline",
              fontSize: {
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "6.3vw",
              },
            }}
            href={`mailto:${i18n.swanData.email}`}
          >
            {i18n.swanData.email}
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            id="tcp"
            sx={{
              fontSize: {
                lg: "20px",
                md: "20px",
                sm: "20px",
                xs: "6.3vw",
              },
            }}
            variant="h5"
          >
            {i18n.swanData.tcp}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Typography id="terms" sx={{ textAlign: "center", marginY: "1rem" }}>
            {"| "}
            <Link onClick={handleShowModal} sx={{ cursor: "pointer" }}>
              {i18n.terms.termAndCond}
            </Link>
            {" |"}
          </Typography>
          <Typography
            id="privacy"
            sx={{ textAlign: "center", marginY: "1rem", marginLeft: "8px" }}
          >
            <Link onClick={handleShowModalPrivacy} sx={{ cursor: "pointer" }}>
              {i18n.privacyPolicy.privacy}
            </Link>
            {" |"}
          </Typography>
        </Grid>
        {showModal && (
          <CustomModal
            open={showModal}
            closeModal={handleCloseModal}
            typeText={"terms"}
            title={i18n.terms.termAndCond}
            text={() => <TermsAndCondition />}
          />
        )}

        {showModalPrivacy && (
          <CustomModal
            open={showModalPrivacy}
            closeModal={handleCloseModalPrivacy}
            typeText={"terms"}
            title={i18n.privacyPolicy.privacy}
            text={() => <PrivacyPolicy />}
          />
        )}
      </Grid>
    </>
  );
};

export default ContactSponsors;
