import React, { useContext, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  Popover,
} from "@mui/material";
import { LanguageContext } from "../../languages/LanguageContext";
import CustomModal from "../CustomModal";
import TermsAndCondition from "../TermsAndCondition";
import PrivacyPolicy from "../PrivacyPolicy";
import { InfoOutlined } from "@mui/icons-material";

const OptInButtonTooltip = () => {
  const { i18n } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);
  const [showModalPrivacy, setShowModalPrivacy] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModalPrivacy = () => setShowModalPrivacy(true);
  const handleCloseModalPrivacy = () => setShowModalPrivacy(false);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const tooltipContent = (
    <Box sx={{ padding: 2 }}>
      <Typography sx={{ marginY: "1rem" }}>
        {i18n.optIn.text1}
        <Link
          id="terms"
          onClick={() => {
            handleCloseModalPrivacy();
            handleShowModal();
          }}
          sx={{ cursor: "pointer", marginLeft: "8px" }}
        >
          {i18n.optIn.hyper1}
        </Link>
        <Box component="span" sx={{ paddingX: "8px" }}>
          {i18n.optIn.text2}
        </Box>
        <Link
          id="privacy"
          onClick={() => {
            handleCloseModal();
            handleShowModalPrivacy();
          }}
          sx={{ cursor: "pointer" }}
        >
          {i18n.optIn.hyper2}
        </Link>
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ zIndex: 15 }}>
      {isMobile ? (
        <>
          <IconButton
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpen}
          >
            <InfoOutlined />
          </IconButton>
          <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handlePopoverClose}
            PaperProps={{
              sx: {
                width: '90%',
                maxWidth: '90%',
                padding: 2,
                margin: 'auto',
                borderRadius: 2,
                pointerEvents: 'auto',
              },
            }}
          >
            {tooltipContent}
          </Popover>
        </>
      ) : (
        <Tooltip placement="top" title={tooltipContent}>
          <IconButton>
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      )}

      {showModal && (
        <CustomModal
          open={showModal}
          closeModal={handleCloseModal}
          typeText={"terms"}
          title={i18n.terms.termAndCond}
          text={() => <TermsAndCondition />}
        />
      )}
      {showModalPrivacy && (
        <CustomModal
          open={showModalPrivacy}
          closeModal={handleCloseModalPrivacy}
          typeText={"terms"}
          title={i18n.privacyPolicy.privacy}
          text={() => <PrivacyPolicy />}
        />
      )}
    </Box>
  );
};

export default OptInButtonTooltip;
