import React, { useEffect, useContext } from "react";
import uuid from "react-uuid";
import dayjs from "dayjs";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import InputLabel from "@mui/material/InputLabel";
import RouteMap from "../../RouteMap/RouteMap";
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
  Button,
  Grid,
  ButtonGroup,
} from "@mui/material";

import {
  validateDateTime,
  validatePassengers,
} from "../../../validation/tripsFormValidation";
import Transport from "./Transport";
import Hourly from "./Hourly";
import Round from "./Round";
import { LanguageContext } from "../../../languages/LanguageContext";
import CustomModal from "../../CustomModal";
import { calculateRoute } from "../../AppContainer/utilities";

const TripsForm = (props) => {
  const { i18n } = useContext(LanguageContext);

  useEffect(() => {
    let joinedDate = dayjs(new Date(props.state.date + " " + props.state.time));
    props.dispatch({ type: "set_date_time", payload: joinedDate });
  }, [props.state.date, props.state.time]);


  useEffect(() => {
    calculateRoute(props.dispatch,props.state);
  }, [
    props.state.pickUp,
    props.state.dateTime,
    props.state.dropOff,
    props.state.stopChanged,
  ]);

  const onLoad = (autocomplete, autocompleteType) => {
    props.dispatch({ type: autocompleteType, payload: autocomplete });
  };

  const onPlaceChanged = (setter, autocomplete) => {
    try {
      props.dispatch({ type: "set_trips_error", payload: "" });
      if (setter === "pickUp" && autocomplete !== null) {
        let place = autocomplete.getPlace();

        props.dispatch({ type: "set_pick_up", payload: place });
        props.dispatch({ type: "set_is_submited_trip", payload: false });
      } else if (setter === "dropOff" && autocomplete !== null) {
        props.dispatch({
          type: "set_drop_off",
          payload: autocomplete.getPlace(),
        });
        props.dispatch({ type: "set_is_submited_trip", payload: false });
      } else {
        console.log("Autocomplete is not loaded yet!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onLoadStop = (autocomplete, stopId) => {
    let findedStop = props.state.stops.find((stop) => stop.id === stopId);
    props.dispatch({
      type: "set_stops",
      payload: props.state.stops.map((stop) => {
        if (stop.id === findedStop?.id) {
          return { ...stop, autocomplete: autocomplete };
        }
        return stop;
      }),
    });
  };

  const onStopPlaceChanged = (stopId) => {
    let findedStop = props.state.stops.find((stop) => stop.id === stopId);
    if (findedStop?.autocomplete !== null) {
      props.dispatch({
        type: "set_stops",
        payload: props.state.stops.map((stop) => {
          if (stop.id === stopId) {
            props.dispatch({
              type: "set_stop_changed",
              payload: !props.state.stopChanged,
            });
            return { ...stop, place: findedStop.autocomplete.getPlace() };
          }
          return stop;
        }),
      });
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handlePassengersChange = (event) => {
    if (event.target.value < Number.MAX_SAFE_INTEGER) {
      props.dispatch({
        type: "set_passengers",
        payload: Number(event.target.value),
      });
    }
  };

  const incrementPassengersCount = () => {
    if (Number(props.state.passengers) < Number.MAX_SAFE_INTEGER - 1) {
      props.dispatch({
        type: "set_passengers",
        payload: Number(props.state.passengers) + 1,
      });
    }
  };
  
  const decrementPassengersCount = () => {
    if (Number(props.state.passengers) > 0) {
      props.dispatch({
        type: "set_passengers",
        payload: Number(props.state.passengers) - 1,
      });
    }
  };

  const handleDateChange = (e) => {
    props.dispatch({ type: "set_date", payload: e.target.value });
  };

  const handleTimeChange = (e) => {
    props.dispatch({ type: "set_time", payload: e.target.value });
  };

  const handleAddStopInput = () => {
    props.dispatch({
      type: "set_stops",
      payload: [
        ...props.state.stops,
        { id: uuid(), place: "", autocomplete: null },
      ],
    });
  };

  const handleStopTimeChange = (event, stopId) => {
    props.dispatch({
      type: "set_stops",
      payload: props.state.stops.map((stop) => {
        if (stop.id === stopId) {
          return { ...stop, wait_time: Number(event.target.value) };
        }
        return stop;
      }),
    });
  };

  const handleDeleteStop = (deleteStopId) => {
    props.dispatch({
      type: "set_stops",
      payload: props.state.stops.filter((stop) => stop.id !== deleteStopId),
    });
    props.dispatch({
      type: "set_stop_changed",
      payload: !props.state.stopChanged,
    });
  };

  const handleModeChange = async (mode) => {
    props.dispatch({ type: "set_directions_response", payload: null });
    props.dispatch({ type: "set_drop_off", payload: "" });
    props.dispatch({ type: "set_pick_up", payload: "" });
    props.dispatch({ type: "set_mode", payload: mode });
    if (mode === "ROUND") {
      props.dispatch({
        type: "set_stops",
        payload: [{ id: uuid(), place: "", autocomplete: null }],
      });
    } else {
      props.dispatch({ type: "set_stops", payload: [] });
    }
  };

  const handleHoursChange = (event) => {
    props.dispatch({ type: "set_hours", payload: event.target.value });
  };

  const handleFlightNumberChange = (event) => {
    props.dispatch({ type: "set_flight_number", payload: event.target.value });
  };

  const handleDropOffCheckbox = () => {
    props.dispatch({
      type: "set_is_drop_off_same",
      payload: !props.state.isDropOffSame,
    });
    props.dispatch({ type: "set_drop_off", payload: "" });
  };

  const map = (
    <Grid item xs={12} height="300px">
      {props.state.googleMapError !== "" && (
        <CustomModal
          dispatch={props.dispatch}
          open={true}
          title="Error"
          text={() => <>{props.state.googleMapError}</>}
          typeText={"error"}
        />
      )}
      {props.isLoaded ? (
        <RouteMap
          directionsResponse={props.state.directionsResponse}
          dropOffMarker={{
            lat: props.state.dropOff?.geometry?.location.lat(),
            lng: props.state.dropOff?.geometry?.location.lng(),
          }}
          state={props.state}
          dispatch={props.dispatch}
        />
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "45%" }}>
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );

  return (
    <React.Fragment>
      {props.state.tripLoad ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: "45%",
            mb: "45%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="h6"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <CalendarMonthIcon sx={{ mr: 1 }} />
            <span>{i18n.appData.tripInformation}</span>
          </Typography>
          <Grid container spacing={3} sx={{ pb: 5 }}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Date"
                value={props.state.date}
                onChange={handleDateChange}
                type="date"
                sx={(t) => {
                  return { colorScheme: t.palette.mode };
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: new Date().toISOString().split("T")[0] },
                }}
                error={
                  !validateDateTime(props.state.dateTime, props.state.pickUp)
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label={i18n.trips.time}
                defaultValue={props.state.time}
                onChange={handleTimeChange}
                sx={(t) => {
                  return { colorScheme: t.palette.mode };
                }}
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                error={
                  !validateDateTime(props.state.dateTime, props.state.pickUp)
                }
                helperText={
                  !validateDateTime(props.state.dateTime, props.state.pickUp)
                    ? i18n.errors.dateTime
                    : i18n.trips.timeExplain
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ fontSize: "13px" }}>
                {i18n.trips.passengers}*
              </InputLabel>
              <TextField
                required
                id="passengers"
                name="passengers"
                sx={{ width: `${String(props.state.passengers).length + 4}ch` }}
                placeholder={i18n.trips.passengersPlaceholder}
                fullWidth
                variant="outlined"
                onChange={handlePassengersChange}
                value={props.state.passengers}
                error={!validatePassengers(props.state.passengers)}
                helperText={
                  validatePassengers(props.state.passengers)
                    ? ""
                    : i18n.errors.placeholder
                }
                InputProps={{ inputProps: { min: 1 } }}
              />

              <ButtonGroup
                orientation="vertical"
                aria-label="vertical contained button group"
                variant="text"
                sx={{ height: "1.4375em", ml: 1 }}
              >
                <KeyboardArrowUpIcon
                  onClick={incrementPassengersCount}
                  sx={{
                    height: "1.1em",
                    "&:hover": { color: (t) => t.palette.primary.main },
                  }}
                />
                <KeyboardArrowDownIcon
                  onClick={decrementPassengersCount}
                  sx={{
                    height: "1.1em",
                    "&:hover": { color: (t) => t.palette.primary.main },
                  }}
                />
              </ButtonGroup>
            </Grid>
          </Grid>
          <Typography
            variant="h6"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <LocationOnOutlinedIcon sx={{ mr: 1 }} />
            <span>{i18n.appData.yourTrip}</span>
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <Button
                  onClick={() => {
                    handleModeChange("HOURLY");
                  }}
                  variant={
                    props.state.mode === "HOURLY" ? "contained" : "outlined"
                  }
                >
                  {i18n.appData.hourly}
                </Button>
                <Button
                  onClick={() => {
                    handleModeChange("TRANSPORT");
                  }}
                  variant={
                    props.state.mode === "TRANSPORT" ? "contained" : "outlined"
                  }
                >
                  {i18n.appData.transfer}
                </Button>
                <Button
                  onClick={() => {
                    handleModeChange("ROUND");
                  }}
                  variant={
                    props.state.mode === "ROUND" ? "contained" : "outlined"
                  }
                >
                  {i18n.appData.roundTrip}
                </Button>
              </ButtonGroup>
            </Grid>

            <Grid item xs={12}>
              {props.state.mode === "HOURLY" && (
                <Hourly
                  state={props.state}
                  dispatch={props.dispatch}
                  map={map}
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  isLoaded={props.isLoaded}
                  scrollToBookingSection={props.scrollToBookingSection}
                  handleAddStopInput={handleAddStopInput}
                  handleDeleteStop={handleDeleteStop}
                  onLoadStop={onLoadStop}
                  handleDropOffCheckbox={handleDropOffCheckbox}
                  onStopPlaceChanged={onStopPlaceChanged}
                  handleHoursChange={handleHoursChange}
                  handleFlightNumberChange={handleFlightNumberChange}
                />
              )}

              {props.state.mode === "TRANSPORT" && (
                <Transport
                  state={props.state}
                  dispatch={props.dispatch}
                  map={map}
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  isLoaded={props.isLoaded}
                  scrollToBookingSection={props.scrollToBookingSection}
                  handleAddStopInput={handleAddStopInput}
                  handleDeleteStop={handleDeleteStop}
                  onLoadStop={onLoadStop}
                  onStopPlaceChanged={onStopPlaceChanged}
                  handleFlightNumberChange={handleFlightNumberChange}
                />
              )}
              {props.state.mode === "ROUND" && (
                <Round
                  state={props.state}
                  dispatch={props.dispatch}
                  map={map}
                  onLoad={onLoad}
                  onPlaceChanged={onPlaceChanged}
                  isLoaded={props.isLoaded}
                  scrollToBookingSection={props.scrollToBookingSection}
                  handleAddStopInput={handleAddStopInput}
                  handleDeleteStop={handleDeleteStop}
                  onLoadStop={onLoadStop}
                  onStopPlaceChanged={onStopPlaceChanged}
                  handleDropOffCheckbox={handleDropOffCheckbox}
                  handleStopTimeChange={handleStopTimeChange}
                  handleFlightNumberChange={handleFlightNumberChange}
                />
              )}
            </Grid>
          </Grid>
          {props.state.tripsError !== "" && (
            <CustomModal
              dispatch={props.dispatch}
              open={true}
              title="Error"
              text={() => <>{props.state.tripsError}</>}
              typeText={"error"}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default TripsForm;
