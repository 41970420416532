import React, { useContext } from "react";
import * as yup from "yup";
import { Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Box";

import { isValidPhoneNumber } from "react-phone-number-input";
import { LanguageContext } from "../../../languages/LanguageContext";
import PhoneNumber from "../../PhoneInput/PhoneNumber";
import ContactTextField from "../../ContactTextField";
import { onTripsFormSubmit } from "../../AppContainer/utilities";
import OptInButtonTooltip from "../../Review/OptInButtonTooltip";

const Round = (props) => {
  const { i18n } = useContext(LanguageContext);
  const initialValues = {
    pickUp: props.state.pickUp?.formatted_address || "",
    stops:
      props.state?.stops.map((a) => a?.place?.formatted_address || "") || [],
    isDropOffSame: props.state.isDropOffSame,
    times: props.state?.stops.map((a) => a?.wait_time || "") || [],
    dropOff: props.state.dropOff?.formatted_address || "",
    first_name: props.state.contactData?.first_name || "",
    last_name: props.state.contactData?.last_name || "",
    email: props.state.contactData?.email || "",
    phone: props.state.contactData?.phone || "",
    flightNumber: props.state.flightNumber || "",
  };

  const transferSchema = yup.object().shape({
    pickUp: yup.string().required(i18n.errors.pickUpRequired),
    flightNumber: yup.string().max(10, i18n.errors.maxFlightNumber),
    stops: yup.array().of(yup.string().required(i18n.errors.stopRequired)),
    times: yup.array().of(
      yup
        .string()
        .matches(/^[0-9]+$/, i18n.errors.mustDigits)
        .required(i18n.errors.stopTimeRequired)
    ),
    isDropOffSame: yup.boolean(),
    dropOff: yup.string().when("isDropOffSame", {
      is: false,
      then: yup.string().required(i18n.errors.dropOffRequired),
    }),
    ...(!props.state.isStaff && {
      first_name: yup.string().required(i18n.errors.firstNameRequired),
    }),
    ...(!props.state.isStaff && {
      last_name: yup.string().required(i18n.errors.lastNameRequired),
    }),
    ...(!props.state.isStaff && {
      email: yup
        .string()
        .email(i18n.errors.emailValid)
        .required(i18n.errors.emailRequired),
    }),
    ...(!props.state.isStaff && {
      phone: yup
        .string()
        .test("valid phone", i18n.errors.phoneValid, (value) =>
          isValidPhoneNumber(String(value))
        )
        .required(i18n.errors.phoneRequired),
    }),
  });

  const onFormSubmit = async (values) => {
    try {
      await onTripsFormSubmit(
        props.dispatch,
        props.state,
        i18n,
        props.scrollToBookingSection
      );
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: transferSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={
              props.state.pickUp !== "" &&
              props.state.pickUp?.types?.includes("airport")
                ? 9
                : 12
            }
          >
            {props.isLoaded && (
              <Autocomplete
                onLoad={(autocomplete) => {
                  props.onLoad(autocomplete, "set_autocomplete_pick_up");
                }}
                onPlaceChanged={() => {
                  props.onPlaceChanged(
                    "pickUp",
                    props.state.autocompletePickUp
                  );
                }}
                options={{
                  strictBounds: false,
                  bounds: {
                    north: props.state.mapCenter.lat + 0.3,
                    south: props.state.mapCenter.lat - 0.3,
                    east: props.state.mapCenter.lng + 0.3,
                    west: props.state.mapCenter.lng - 0.3,
                  },
                }}
              >
                <TextField
                  id="pickUp"
                  name="pickUp"
                  label={i18n.trips.pickUp}
                  defaultValue={formik.values.pickUp}
                  onChange={() =>
                    formik.setFieldValue("pickUp", () => {
                      props.onPlaceChanged(
                        "pickUp",
                        props.state.autocompletePickUp
                      );
                    })
                  }
                  error={formik.touched.pickUp && Boolean(formik.errors.pickUp)}
                  helperText={formik.touched.pickUp && formik.errors.pickUp}
                  fullWidth
                  variant="standard"
                  placeholder={i18n.trips.enterLocation}
                />
              </Autocomplete>
            )}
          </Grid>
          {props.state.pickUp !== "" &&
            props.state.pickUp?.types?.includes("airport") && (
              <Grid item xs={3}>
                <TextField
                  id="flightNumber"
                  label={i18n.trips.flightNumber}
                  onChange={(e) => {
                    formik.handleChange(e.target.value);
                    props.handleFlightNumberChange(e);
                  }}
                  defaultValue={props.state.flightNumber}
                  variant="standard"
                  error={
                    formik.touched.flightNumber &&
                    Boolean(formik.errors.flightNumber)
                  }
                  helperText={
                    formik.touched.flightNumber && formik.errors.flightNumber
                  }
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  name="isDropOffSame"
                  value={props.state.isDropOffSame}
                  checked={props.state.isDropOffSame}
                  onChange={() => {
                    formik.setFieldValue(
                      "isDropOffSame",
                      !props.state.isDropOffSame
                    );
                    formik.setFieldValue("dropOff", "");
                    props.handleDropOffCheckbox();
                  }}
                />
              }
              label={i18n.trips.returnAddress}
            />
          </Grid>
          {props.state?.stops.length !== 0 &&
            props.state.stops.map((stop, index) => (
              <React.Fragment key={stop.id}>
                <Grid item xs={9}>
                  {props.isLoaded && (
                    <Autocomplete
                      onLoad={(autocomplete) => {
                        props.onLoadStop(autocomplete, stop.id);
                      }}
                      onPlaceChanged={() => {
                        props.onStopPlaceChanged(stop.id);
                      }}
                      options={{
                        strictBounds: false,
                        bounds: {
                          north: props.state.mapCenter.lat + 0.3,
                          south: props.state.mapCenter.lat - 0.3,
                          east: props.state.mapCenter.lng + 0.3,
                          west: props.state.mapCenter.lng - 0.3,
                        },
                      }}
                    >
                      <TextField
                        name={`stops[${index}]`}
                        label={`${i18n.trips.stop}`}
                        error={
                          formik.errors.stops &&
                          formik.touched.stops &&
                          Boolean(formik.errors.stops[index])
                        }
                        helperText={
                          formik.errors.stops &&
                          formik.touched.stops &&
                          formik.errors.stops[index]
                        }
                        onChange={() =>
                          formik.setFieldValue(`stops[${index}]`, () => {
                            props.onStopPlaceChanged(stop.id);
                          })
                        }
                        fullWidth
                        variant="standard"
                        InputProps={{
                          endAdornment: props.state.mode === "ROUND" &&
                            index !== 0 && (
                              <InputAdornment position="end">
                                <ClearIcon
                                  style={{ cursor: "pointer" }}
                                  label={i18n.trips.deleteStop}
                                  onClick={() => {
                                    props.handleDeleteStop(stop.id);
                                  }}
                                ></ClearIcon>
                              </InputAdornment>
                            ),
                        }}
                      />
                    </Autocomplete>
                  )}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="stop_time"
                    name={`times[${index}]`}
                    label={i18n.trips.stopTime}
                    error={
                      formik.errors.times &&
                      formik.touched.times &&
                      Boolean(formik.errors.times[index])
                    }
                    helperText={
                      formik.errors.times &&
                      formik.touched.times &&
                      formik.errors.times[index]
                    }
                    onChange={(event) => {
                      formik.setFieldValue(
                        `times[${index}]`,
                        event.target.value
                      );
                      props.handleStopTimeChange(event, stop.id);
                    }}
                    variant="standard"
                  />
                </Grid>
              </React.Fragment>
            ))}
          {!props.state.isDropOffSame && (
            <Grid item xs={12}>
              {props.isLoaded && (
                <Autocomplete
                  onLoad={(autocomplete) => {
                    props.onLoad(autocomplete, "set_autocomplete_drop_off");
                  }}
                  onPlaceChanged={() => {
                    props.onPlaceChanged(
                      "dropOff",
                      props.state.autocompleteDropOff
                    );
                  }}
                  options={{
                    strictBounds: false,
                    bounds: {
                      north: props.state.mapCenter.lat + 0.3,
                      south: props.state.mapCenter.lat - 0.3,
                      east: props.state.mapCenter.lng + 0.3,
                      west: props.state.mapCenter.lng - 0.3,
                    },
                  }}
                >
                  <TextField
                    id="dropOff"
                    name="dropOff"
                    defaultValue={formik.values.dropOff}
                    label={i18n.trips.dropOff}
                    onChange={() =>
                      formik.setFieldValue("dropOff", () => {
                        props.onPlaceChanged(
                          "dropOff",
                          props.state.autocompletePickUp
                        );
                      })
                    }
                    error={
                      formik.touched.dropOff && Boolean(formik.errors.dropOff)
                    }
                    helperText={formik.touched.dropOff && formik.errors.dropOff}
                    fullWidth
                    placeholder={i18n.trips.enterLocation}
                    variant="standard"
                  />
                </Autocomplete>
              )}
            </Grid>
          )}
          {props.map}
          {!props.state.isStaff && (
            <>
              <Grid item md={12} xs={12} sm={12} sx={{ marginTop: "2rem" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {i18n.contact.contactDetails}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactTextField
                  name={"first_name"}
                  label={i18n.contact.firstName}
                  formik={formik}
                  dispatch={props.dispatch}
                  state={props.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactTextField
                  name={"last_name"}
                  label={i18n.contact.lastName}
                  formik={formik}
                  dispatch={props.dispatch}
                  state={props.state}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ContactTextField
                  name={"email"}
                  type={"email"}
                  label={i18n.contact.email}
                  formik={formik}
                  dispatch={props.dispatch}
                  state={props.state}
                />
              </Grid>
              <Grid item container xs={12} sm={6}>
                <Grid item xs={10}>
                  <PhoneNumber
                    formik={formik}
                    dispatch={props.dispatch}
                    state={props.state}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                  }}
                >
                  <OptInButtonTooltip />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }}>
                {i18n.appData.next}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default Round;
