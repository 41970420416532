import React from "react";

import { Box, Stack, Typography } from "@mui/material";

const CustomCarShow = (props) => {
  return (
    <Stack direction={"column"} sx={{ height: '100%', position:'relative' }}>
      <Box
        component="img"
        src={props.src}
        paddingTop={props.paddingTop}
        paddingLeft={props.paddingLeft}
        marginTop={props.marginTop}
        sx={{height: props.height}}
      />
      <Typography
        variant="h6"
        textAlign={"center"}
        textTransform={"uppercase"}
        fontWeight="bold"
        color={"black"}
        marginBottom="0px"
        marginTop={"auto"}
        paddingBottom="0px"
        position={'relative'}
        bottom={'0'}
      >
        {props.carTitle}
      </Typography>
      <Typography variant="body"
        textAlign={"center"}
        fontWeight="bold"
        position={'relative'}
        bottom={'0'}>
        {props.carName}
      </Typography>
    </Stack>
  );
};

export default CustomCarShow;
