import React from "react";

import { Box, Stack, Typography } from "@mui/material";

const CustomCarShowSedan = (props) => {
  return (
    <Stack
      direction={"column"}
      sx={{ height: "100%", position: "relative", marginTop: "0px" }}
    >
      <Box
        component="img"
        src={props.src}
        paddingTop={props.paddingTop}
        paddingLeft={props.paddingLeft}
        marginTop={"34px"}
        sx={{ height: props.height }}
      />
      <Typography
        variant="h6"
        textAlign={"center"}
        textTransform={"uppercase"}
        fontWeight="bold"
        color={"black"}
        marginBottom="0px"
        marginTop={"-24px"}
        paddingBottom="0px"
        position={"relative"}
        bottom={"0"}
      >
        {props.carTitle}
      </Typography>
      <Typography
        variant="body"
        textAlign={"center"}
        fontWeight="bold"
        position={"relative"}
        bottom={"0"}
      >
        {props.carName}
      </Typography>
    </Stack>
  );
};

export default CustomCarShowSedan;
