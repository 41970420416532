import { Box, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import Reservation from "../../models/Reservation";
import Stop from "../../models/Stop";
import Trip from "../../models/Trip";
import { pricingService } from "../../services/pricing";
import { reservationsService } from "../../services/reservations";
import { staffService } from "../../services/staffService";
import { tripsService } from "../../services/trips";
import { validateTripsForm } from "../../validation/tripsFormValidation";
import { isVehicleSelected } from "../../validation/vehiclesFormValidation";
import TripsForm from "../Forms/TripsForm/TripsForm";
import VehicleForm from "../Forms/VehicleForm/VehicleForm";
import Review from "../Review/Review";

export const reloadApi = 30000;
export const libraries = ["places"];

export function getStepContent(props) {
    switch (props.step) {
        case 0:
            return (
                <TripsForm
                    state={props.state}
                    isLoaded={props.isLoaded}
                    dispatch={props.dispatch}
                    scrollToBookingSection={props.scrollToBookingSection}
                />
            );
        case 1:
            return (
                <VehicleForm
                    state={props.state}
                    dispatch={props.dispatch}
                    scrollToBookingSection={props.scrollToBookingSection}
                />
            );
        case 2:
            return (
                <Review
                    state={props.state}
                    dispatch={props.dispatch}
                    isLoaded={props.isLoaded}
                    scrollToBookingSection={props.scrollToBookingSection}
                />
            );
        case 4:
            return (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "45%",
                        mb: "45%",
                    }}
                >
                    <CircularProgress />
                </Box>
            );
        default:
            throw new Error("Unknown step");
    }
}

export const reducer = (state, action) => {
    switch (action.type) {
        case "set_active_step":
            return { ...state, activeStep: action.payload };
        case "set_passengers":
            return { ...state, passengers: action.payload };
        case "set_directions_response":
            return { ...state, directionsResponse: action.payload };
        case "set_distance":
            return { ...state, distance: action.payload };
        case "set_date_time":
            return { ...state, dateTime: action.payload };
        case "set_mode":
            return { ...state, mode: action.payload };
        case "set_pick_up":
            return { ...state, pickUp: action.payload };
        case "set_drop_off":
            return { ...state, dropOff: action.payload };
        case "set_stops":
            return { ...state, stops: action.payload };
        case "set_flight_number":
            return { ...state, flightNumber: action.payload };
        case "set_hours":
            return { ...state, hours: action.payload };
        case "set_is_drop_off_same":
            return { ...state, isDropOffSame: action.payload };
        case "set_trip_quotes":
            return { ...state, tripQuotes: action.payload };
        case "set_selected_vehicle":
            return { ...state, selectedVehicle: action.payload };
        case "set_contact_data":
            return { ...state, contactData: action.payload };
        case "set_utc_offset":
            return { ...state, utcOffset: action.payload };
        case "set_reservation_error":
            return { ...state, reservationError: action.payload };
        case "set_trips_error":
            return { ...state, tripsError: action.payload };
        case "set_contact_error":
            return { ...state, contactError: action.payload };
        case "set_google_map_error":
            return { ...state, googleMapError: action.payload };
        case "set_route_duration":
            return { ...state, routeDuration: action.payload };
        case "set_autocomplete_pick_up":
            return { ...state, autocompletePickUp: action.payload };
        case "set_autocomplete_drop_off":
            return { ...state, autocompleteDropOff: action.payload };
        case "set_stop_changed":
            return { ...state, stopChanged: action.payload };
        case "set_trip_load":
            return { ...state, tripLoad: action.payload };
        case "set_reservation_load":
            return { ...state, reservationLoad: action.payload };
        case "set_captcha_token":
            return { ...state, captchaToken: action.payload };
        case "set_is_desktop":
            return { ...state, isDesktop: action.payload };
        case "set_map_center":
            return { ...state, mapCenter: action.payload };
        case "set_pricing_data":
            return { ...state, pricingData: action.payload };
        case "set_date":
            return { ...state, date: action.payload };
        case "set_time":
            return { ...state, time: action.payload };
        case "set_is_staff":
            return { ...state, isStaff: action.payload };
        case "set_reservation_pay_modal":
            return { ...state, reservationPayModal: action.payload };
        default:
            return state;
    }
};

export const getPricing = async (dispatch) => {
    try {
        let price = await pricingService.getPricing();

        if (price.status === 200) {
            dispatch({ type: "set_pricing_data", payload: price.data });
        }
    } catch (error) {
        console.log(error);
    }
};

export const handleBack = (dispatch, state, scrollToBookingSection = null) => {
    dispatch({ type: "set_active_step", payload: state.activeStep - 1 });
    scrollToBookingSection && scrollToBookingSection();
};

export const onTripsFormSubmit = async (dispatch, state, i18n, scrollToBookingSection = null) => {
    try {
        dispatch({ type: "set_is_submited_trip", payload: true });
        dispatch({ type: "set_trip_load", payload: true });
        scrollToBookingSection && scrollToBookingSection();
        dispatch({ type: "set_trips_error", payload: "" });
        let tripStopsData = [
            new Stop(
                1,
                state.pickUp.formatted_address,
                state.pickUp?.types?.includes("airport") ? "airport" : "default",
                "PICK UP",
                state.flightNumber,
                state.pickUp?.geometry?.location.lat(),
                state.pickUp?.geometry?.location.lng(),
                0,
                state.pickUp?.name
            ),
            ...state.stops.map(
                (stop, index) =>
                    new Stop(
                        index + 2,
                        stop.place.formatted_address,
                        "default",
                        "STOP",
                        "",
                        stop.place.geometry?.location.lat(),
                        stop.place.geometry?.location.lng(),
                        stop?.wait_time,
                        stop.place?.name
                    )
            ),
        ];
        if (!state.isDropOffSame || state.mode === "TRANSPORT") {
            tripStopsData.push(
                new Stop(
                    state.stops.length + 2,
                    state.dropOff.formatted_address,
                    "default",
                    "DROP OFF",
                    "",
                    state.dropOff?.geometry?.location.lat(),
                    state.dropOff?.geometry?.location.lng(),
                    0,
                    state.dropOff.place?.name
                )
            );
        }

        let joinedDate = dayjs(new Date(state.date + " " + state.time));
        dispatch({ type: "set_date_time", payload: joinedDate });

        let tripData = new Trip(
            tripStopsData,
            joinedDate.format("YYYY-MM-DD HH:mm"),
            state.passengers,
            state.mode,
            Number(state.hours) * 60,
            state.isDropOffSame,
            state.contactData
        );
        if (
            validateTripsForm(tripData) &&
            state.googleMapError === "" &&
            state.tripsError === ""
        ) {
            state.isStaff ? 
            staffService
                .postTrip(tripData)
                .then((res) => {
                    dispatch({ type: "set_trip_quotes", payload: res });
                    dispatch({ type: "set_active_step", payload: 1 });
                    dispatch({
                        type: "set_trips_error",
                        payload: "",
                    });
                    dispatch({ type: "set_trip_load", payload: false });
                })
                .catch((error) => {
                    dispatch({ type: "set_trip_load", payload: false });
                    if (error?.response?.data) {
                        dispatch({
                            type: "set_trips_error",
                            payload: Object.values(error.response.data)[0],
                        });
                    } else {
                        dispatch({
                            type: "set_trips_error",
                            payload: i18n.errors.serverError,
                        });
                    }
                })
                :
                tripsService
                .postTrip(tripData)
                .then((res) => {
                    dispatch({ type: "set_trip_quotes", payload: res });
                    dispatch({ type: "set_active_step", payload: 1 });
                    dispatch({
                        type: "set_trips_error",
                        payload: "",
                    });
                    dispatch({ type: "set_trip_load", payload: false });
                })
                .catch((error) => {
                    dispatch({ type: "set_trip_load", payload: false });
                    if (error?.response?.data) {
                        dispatch({
                            type: "set_trips_error",
                            payload: Object.values(error.response.data)[0],
                        });
                    } else {
                        dispatch({
                            type: "set_trips_error",
                            payload: i18n.errors.serverError,
                        });
                    }
                })
        } else {
            dispatch({ type: "set_trip_load", payload: false });
            dispatch({ type: "set_active_step", payload: 0 });
        }
    } catch (error) {
        dispatch({ type: "set_active_step", payload: 0 });
        if (error?.response?.data) {
            let err = Object.values(error.response.data);

            dispatch({ type: "set_trip_load", payload: false });
            dispatch({
                type: "set_trips_error",
                payload: Object.values(err[0])[0],
            });
        } else {
            dispatch({ type: "set_trip_load", payload: false });
            dispatch({
                type: "set_trips_error",
                payload: i18n.errors.serverError,
            });
        }
    }
};

export const onVehicleSelectionSubmit = (dispatch, state) => {
    if (isVehicleSelected(state.selectedVehicle)) {
        dispatch({ type: "set_active_step", payload: 2 });
    }
};

export const onMakeReservation = async (dispatch, state, i18n, scrollToBookingSection = null) => {
    try {
        dispatch({ type: "set_reservation_load", payload: true });
        dispatch({
            type: "set_reservation_error",
            payload: "",
        });
        scrollToBookingSection && scrollToBookingSection();
        const selectedQuote = state.tripQuotes.quotes.filter(
            (quote) => quote.vehicle_type.id === state.selectedVehicle.id
        )[0];

        let reservationData = new Reservation(
            state.tripQuotes.trip.id,
            selectedQuote.id,
            Number.parseInt(state.routeDuration / 60)
        );

        let res = await reservationsService.postReservation(reservationData);
        if (res.status === 201) {
            // let url = res.data.stripe_url;
            // window.location.href = url;
            dispatch({ type: "set_reservation_load", payload: false });
            // dispatch({ type: "set_active_step", payload: 4 });
            dispatch({ type: "set_reservation_pay_modal", payload: true })
        }
    } catch (error) {
        if (error) {
            if (error.response?.data) {
                let err = Object.values(error.response.data);
                dispatch({
                    type: "set_reservation_error",
                    payload: Object.values(err[0])[0],
                });
                dispatch({ type: "set_reservation_load", payload: false });
            } else {
                dispatch({
                    type: "set_reservation_error",
                    payload: i18n.errors.serverError,
                });
                dispatch({ type: "set_reservation_load", payload: false });
            }
        }
    }
};

/*Trip route calculation */
export const calculateRoute = async (dispatch,state) => {
    dispatch({
        type: "set_google_map_error",
        payload: "",
    });
    let destination = "";
    if (
        state.dropOff === "" &&
        state.isDropOffSame &&
        state.mode === "ROUND"
    ) {
        destination = state.pickUp;
    } else {
        destination = state.dropOff;
    }
    if (
        state.pickUp === "" ||
        destination === "" ||
        state.mode === "HOURLY"
    ) {
        return;
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();

    directionsService
        .route({
            origin: state.pickUp.formatted_address,
            destination: destination.formatted_address,
            waypoints: state.stops
                .filter((stop) => stop?.place !== "")
                .map((stop) => ({
                    location: stop?.place?.formatted_address,
                    stopover: true,
                })),
            // optimizeWaypoints: true,
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
            drivingOptions: {
                departureTime: new Date(
                    dayjs(state.dateTime).utcOffset(
                        state.pickUp["utc_offset_minutes"],
                        true
                    )
                ),
                trafficModel: "bestguess",
            },
        })
        .then((response) => {
            dispatch({ type: "set_directions_response", payload: response });
            dispatch({
                type: "set_distance",
                payload: response.routes[0].legs.reduce(
                    (acc, current) => acc + current.distance.value,
                    0
                ),
            });
            dispatch({
                type: "set_route_duration",
                payload: response.routes[0].legs.reduce(
                    (acc, current) => acc + current.duration.value,
                    0
                ),
            });
        })
        .catch((error) => {
            dispatch({
                type: "set_google_map_error",
                payload: "Can not calculate that route!",
            });
        });
};