import React, { useEffect, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import dayjs from "dayjs";
import Link from "@mui/material/Link";
import API from "../../api/api";
import MobileContainer from "./MobileContainer";
import DesktopContainer from "./DesktopContainer";
import CustomModal from "../CustomModal";
import { LanguageContext } from "../../languages/LanguageContext";
import { getPricing, reloadApi, libraries, reducer } from "./utilities";

const AppContainer = (props, ref) => {
  const { i18n } = useContext(LanguageContext);

  const steps = [i18n.steps.trip, i18n.steps.vehicle, i18n.steps.review];

  const [state, dispatch] = React.useReducer(reducer, {
    activeStep:
      window.location.href.includes("success-payment") ||
        window.location.href.includes("save-payment")
        ? 3
        : 0,
    passengers: 1,
    directionsResponse: null,
    distance: "",
    dateTime: dayjs().add(2, "minute"),
    mode: "TRANSPORT",
    pickUp: "",
    dropOff: "",
    stops: [],
    flightNumber: "",
    hours: "",
    isDropOffSame: true,
    tripQuotes: null,
    selectedVehicle: null,
    contactData: null,
    utcOffset: 0,
    reservationError: "",
    tripsError: "",
    googleMapError: "",
    contactError: [],
    routeDuration: 0,
    autocompletePickUp: null,
    autocompleteDropOff: null,
    stopChanged: false,
    tripLoad: false,
    reservationLoad: false,
    captchaToken: null,
    isDesktop: window.innerWidth > 600,
    mapCenter: { lat: 41.50159605237055, lng: -81.67749575406604 },
    pricingData: null,
    date: new Date().toISOString().split("T")[0],
    time:
      new Date().toTimeString().split(":")[0] +
      ":" +
      new Date().toTimeString().split(":")[1],
    isStaff: false,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const cancelStatus = window.location.href.includes("cancel-payment");

  const updateMedia = () => {
    dispatch({ type: "set_is_desktop", payload: window.innerWidth > 600 });
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    // getPricing(dispatch);
    dispatch({ type: "set_is_staff", payload: props.staff });
  }, []);

  useEffect(() => {
    API.get("/");
    const interval = setInterval(() => {
      API.get("/");
    }, reloadApi);

    return () => clearInterval(interval);
  }, []);



  return (
    <div ref={ref}>
      {cancelStatus && !props.staff && (
        <CustomModal
          open={cancelStatus}
          typeText={"cancelPayment"}
          text={() => (
            <>
              {i18n.payment.cancelText}{" "}
              <Link href={`mailto:${i18n.swanData.email}`}>
                {i18n.swanData.email}
              </Link>
            </>
          )}
          title={i18n.payment.cancelTitle}
        />
      )}

      {state.isDesktop ? (
        <DesktopContainer
          state={state}
          steps={steps}
          isLoaded={isLoaded}
          dispatch={dispatch}
          scrollToBookingSection={!props.staff ? props.scrollToBookingSection : null}
        />
      ) : (
        <MobileContainer
          state={state}
          steps={steps}
          isLoaded={isLoaded}
          dispatch={dispatch}
          scrollToBookingSection={!props.staff ? props.scrollToBookingSection : null}
        />
      )}
    </div>
  );
};

export default React.forwardRef(AppContainer);
