import React from "react";

import { Button, Link } from "@mui/material";

const CustomButton = (props) => {
  return (
    <Button
      onClick={props?.onClick}
      sx={(t) => ({
        background: `${t.palette.primary.main}`,
        color: "white",
        width: "250px",
        padding: 1.5,
        fontSize: 30,
        margin: 3,
        borderRadius: "10px 10px",
        fontWeight: "bold",
        textTransform: "none",
        letterSpacing: 0.5,
      })}
    >
      {props?.href ? (
        <Link
          sx={{ color: "white", textDecoration: "none" }}
          href={props?.href}
        >
          {props.text}
        </Link>
      ) : (
        props.text
      )}
    </Button>
  );
};

export default CustomButton;
