import React from "react";
import { Box, Grid, Link} from "@mui/material";
import { logo } from "../../resources/images";

const Title = () => {
  return (
    <Grid container paddingTop={10}>
      <Grid
        item
        xs={12}
        alignSelf="center"
        textAlign="center"
      >
        <Link href="/">
          <Box
            component="img"
            src={logo.src}
            sx={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Link>
      </Grid>
    </Grid>
  );
};

export default Title;
