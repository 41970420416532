import React, { useContext } from "react";
import { Typography } from "@mui/material";
import { LanguageContext } from "../languages/LanguageContext";

const PrivacyPolicy = () => {
  const { i18n } = useContext(LanguageContext);
  return (
    <>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph1}</Typography>

      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title2}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph2}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title3}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph3}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title3}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph3}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title4}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph4}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title5}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph5}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title6}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph6}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title7}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph7}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title8}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph8}</Typography>
      <Typography variant="h6" component="h2">
        {i18n.privacyPolicy.title9}
      </Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph9}</Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph10}</Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph11}</Typography>
      <Typography variant="body1">{i18n.privacyPolicy.paragraph12}</Typography>
      <Typography variant="body1" paddingTop={"1rem"}>
        {i18n.privacyPolicy.paragraph13}
      </Typography>
    </>
  );
};

export default PrivacyPolicy;
